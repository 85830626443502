// Thème BiblioPIAF.

@use "sass:math";

//
// PIAF colors.
//

$action: #484858;
$action-dark: #272733;
$action-light-bg: #fff;
$action-light-color: #212529; // Same as color-yiq($action-light-bg)
$action-light-border-color: rgba(#000, 0.25);
$accent-light: #f2e1cb;
$accent-dark: #797361;

@import "bootstrap_custom";
@import "print";

//
// Kerko elements.
//

.pager-top {
  > nav.mb-4 {
    margin-bottom: 0 !important; // Convoluted, but avoids overriding a template!
  }
}
.search-item a:not(.btn-link) {
  color: $body-color;
}
.search-abstract {
  color: #545454;
  font-size: 0.875em;
}
.header-piaf {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  row-gap: 1.25rem;
  column-gap: 4rem;
}
.footer-piaf {
  background: $action-dark;
  color: $accent-light;
  &__link {
    color: $accent-light;
    @include hover-focus() {
      color: $accent-light;
    }
  }
}
.piaf-incontournable-icon {
  color: darken($accent-light, 30%);
  &__result {
    font-size: 1.25em;
    margin-left: 0.5rem;
  }
}
